/**
 * Wrappers around the WordPress backend.
 *
 * All data from the WordPress backend should go through here.
 *
 * NOTE: This is no longer only WordPress backend, but also
 * the webhook API. Should be renamed to something more generic.
 */

import axios from 'axios';
import { config } from '../config';

export function postBuild(data: string) {
  return axios.request({
    method: 'post',
    url: `${config.wordpressRootUrl}/wp-json/wp/v2/builds`,
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    auth: {
      username: 'apiuser',
      password: '2pT4^ABM8o@Lis7csd&#iHCs',
    },
  });
}

export function uploadImageFromSales(orderId: string, base64data: string, title: string) {
  return fetch(`${config.wordpressRootUrl}/wp-json/codehouse/v1/uploadimagefromsales`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      orderId: orderId,
      base64: base64data,
      title: title,
    }),
  });
}

export function uploadImageFromSales2(orderId: string, base64data: string) {
  return fetch(`${config.integrationRootUrl}/api/wp-update/updateFromSales`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ key: 'rye3rfa1aqh@WKW2xdz', orderId: orderId, base64: base64data }),
  });
}

export function sendRequestInfo(mailData: any) {
  return fetch(`${config.integrationRootUrl}/api/email/sendRequestInfo`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(mailData),
  });
}

export function uploadImage(title: string, base64imageData: string) {
  return fetch(`${config.wordpressRootUrl}/wp-json/codehouse/v1/uploadImage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ title: title, base64_img: base64imageData }),
  });
}
